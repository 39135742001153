.header {
  padding: 20px 0px 10px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10; /* Ensure header stays above other content */
}

.MuiDrawer-root {
  z-index: 15; /* Ensure drawer stays above header */
}

.headingLine>p {
  font-size: 18px;
  word-spacing: 8px;
  margin: 0px;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .header {
    padding: 15px 5px;
  }

  .logoutButton {
    height: 35px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 10px 5px;
    grid-template-columns: 15% 43% 35%;
  }

  .logoutButton {
    height: 30px;
    font-size: 12px;
  }
}

@media (max-width: 320px) {
  .header {
      grid-template-columns: 15% 37% 35%;
  }
}
