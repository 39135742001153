

.full-height-background {
  position: relative;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(../../../Assets/Image/stethoscop.jpg);
background-repeat: no-repeat;
background-position: center;
background-size: cover;
  min-height: 100vh; /* Full screen height */
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  padding: 20px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3); /* Optional dark overlay */
  z-index: 1;
}

.login-form {
  position: relative;
  z-index: 2;
  background-color: rgba(171, 162, 162, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  box-shadow: 0 8px 16px #0000004d;
  width: 100%;
  max-width: 350px;
  padding: 10px 20px;
}

.form-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}


.text-center {
  text-align: center;
}

.form{
  width: 80%;
 color: #000;
 }
.form-button {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .login-form {
    padding: 20px;
  }

  .form-button button {
    width: 100%;
  }
}


@media (max-width: 480px) {

 
  .login-form {
    padding: 10px;
    max-width: 300px;
  }
}

@media (max-width: 320px) {

  .login-form {
    max-width: 250px;
    padding: 10px;
  }
 
}




.products-image {
  position: relative;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  background-image: url("../../../Assets/Image/products.jpg"); /* Replace with your image URL */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.add-products {
  position: relative;
  height: 1200px; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  background-image: url("../../../Assets/Image/pro.jpg"); /* Replace with your image URL */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
}
.cancel {
  display: flex;
  justify-content: space-between;
}




.password-full-background {
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4)
    ),
    url(../../../Assets/Image/admin.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.password-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
  width: 90%;
  max-width: 400px;
  padding: 20px;
}

.password-form-container {
  text-align: center;
}

.password-icon {
  font-size: 35px;
  color: #fff;
  margin-bottom: 10px;
}

.password-heading {
  font-size: 24px;
  color: #fff;
  margin-bottom: 20px;
}

.password-form-input {
  width: 90%;

  padding: 10px;
  color: #fff;
}

.password-form-input label {
  color: #fff;
}

.password-form-input input {
  color: #fff;
}

.password-form-button {
  margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .password-full-background {
    padding: 20px;
  }
  .password-full-background {
    margin-top: 63px 
  }

  .password-overlay {
    width: 50%;
    max-width: 100%;
    padding: 15px;
  }
}
@media (max-width: 576px) {
  .password-full-background {
    margin-top: 55px ;
    padding-bottom: 100px;
  }

  .password-overlay {
    width: 100%;
    max-width: 100%;
    padding: 15px;
  }
}