.adminhome-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
  padding-top: 60px;

}




.summary-card {
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  flex: 1 1 calc(33.333% - 40px); /* Responsive layout */
  max-width: calc(33.333% - 40px);
}

.summary-title {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.summary-value {
  font-size: 24px;
  font-weight: bold;
  color: #555;
}

.chart-container {
  width: 100%;
  height: 400px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

 
  
  /* Responsive design */

  @media (max-width: 480px) {
    .adminhome-container {
          flex-direction: column;
         
    }
    .summary-card {
     max-width: 100%; 
    }
  }