.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    padding: 20px;
  }
  
  .not-found-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .not-found-container>  h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .home-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: rgb(64, 99, 56);
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 20px;
    transition: background-color 0.3s;
  }
  
  .home-link:hover {
    background-color: white;
    color:  rgb(64, 99, 56);
    border-color: rgb(64, 99, 56);
    border: 1px solid;
    border-radius: 5px;
  }
  
  /* Responsive design */
  
  @media (min-width: 768px) {
    .not-found-container {
      padding: 40px;
    }
  
    .not-found-container>  h1{
      font-size: 2.5rem;
    }
  
    .home-link {
      font-size: 1.5rem;
      padding: 12px 24px;
    }
  }
  
  @media (min-width: 1024px) {
  .not-found-container>  h1 {
      font-size: 3rem;
    }
  
    .home-link {
      padding: 14px 30px;
      font-size: 1.8rem;
    }
  }
  