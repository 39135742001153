/* Base styles */
.manage-booking {
    position: relative;
    top: 60px;
    padding: 50px 100px;
}

.manage-bookings {
    position: relative;
    top: 60px;
}

.notfoundbooking {
    padding: 30px 500px;
    text-align: center; /* Center text by default */
}

.notfoundbooking > img {
    width: 100%; /* Make image responsive */
    max-width: 500px; /* Ensure the image does not exceed its original size */
}

.notfoundbooking > p {
    position: relative; /* Changed to relative for better responsiveness */
   
    font-size: 20px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: bold;
    color: rgb(200, 104, 104);
    margin: 0 auto; /* Center the text horizontally */
    max-width: 80%; /* Ensure the text does not exceed the container width */
}

/* Responsive styles */
@media (max-width: 1200px) {
    .manage-booking, .manage-bookings {
        padding: 50px 50px; /* Adjust padding for medium-sized screens */
    }

    .notfoundbooking {
        padding: 60px 20px; /* Adjust padding for medium-sized screens */
    }
}

@media (max-width: 768px) {
    .manage-booking, .manage-bookings {
        padding: 30px 20px; /* Adjust padding for small screens */
      top:70px

    }

    .notfoundbooking {
        padding: 0px 10px; /* Adjust padding for small screens */
    }

    .notfoundbooking > p {
        font-size: 16px; /* Reduce font size for small screens */
    }
}

@media (max-width: 480px) {
    .manage-booking, .manage-bookings {
        padding: 20px 10px; /* Adjust padding for extra-small screens */
        top:100px
    }

    .notfoundbooking {
        padding: 20px 5px; /* Adjust padding for extra-small screens */
    }

    .notfoundbooking > p {
        font-size: 14px; /* Reduce font size further for extra-small screens */
        position: static; /* Remove absolute positioning for small screens */
        margin: 10px auto; /* Center text with margin */
    }
}



/* Base styles for table */
.products-table {
    overflow-x: auto; /* Allow horizontal scrolling */
}

.table {
    width: 100%; /* Full width of the container */
    border-collapse: collapse; /* Collapse table borders */
}

.table th, .table td {
    text-align: center; /* Align text to the left */
    padding: 8px; /* Padding inside cells */
    border: 1px solid #ddd; /* Add borders to cells */
}

/* Header styles */
.table thead {
    background-color: #f8f9fa; /* Light background for headers */
}

