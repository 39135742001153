.manage-products{
    position: relative;
    top: 60px;
    padding: 50px 100px;
}

.products-table{
  background-color: white;
  box-shadow: 0 0 10px black;
  border-radius: 10px;
  padding: 10px;
}
.products-table >p{
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    font-size: 30px;
    color: black;
}
table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    text-align:  center;

  }
  
  td, th {
    border: 1px solid #dddddd;
    
    text-align: left;
    padding: 8px;
    align-content: center;
    text-align:  center;

  }
  td>img{
    width:200px;
    height:200px;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }

  .icons{
    display: flex;
    justify-content: space-evenly;
  }
  .dialog{
    width: 500px;
  }

  .update-form{
    display: flex;
  flex-direction: column;
  }
  .image-delete-button{
    display: flex;
    justify-content: space-evenly;
  }
  .up-add-features{
    display: flex;
    justify-content: space-between;
  }

 

  .images-up{
    display: grid;
    grid-template-columns: 23% 23% 23% 23%;
    gap:20px;
    margin-top: 10px;
  }
  .up-sub-image,.up-description,.up-main-image,.up-name,.up-add-features{
    margin-top: 20px !important;
  }
  .texts{
    margin-top: 20px !important;
    width: 70%;
  }
/* Add Bootstrap responsive design */
@media (max-width: 1200px) {
  .texts {
    width: 70%;
  }

    .manage-products {
      padding: 30px 50px;
    }
    .products-table {
      padding: 15px 30px 30px 30px;
    }
    .products-table > p {
      font-size: 25px;
    }
    table {
      font-size: 16px;
    }
    td, th {
      padding: 6px;
    }
  }
  
  @media (max-width: 768px) {
    .texts {
      width: 80%;
    }
    .manage-products {
      padding: 20px 30px;
      top:70px
    }
    .products-table {
      padding: 10px 20px 20px 20px;
    }
    .products-table > p {
      font-size: 20px;
    }
    table {
        font-size: 14px;
      }
      td, th {
        padding: 4px;
      }

    td > img {
        width: 150px;
        height: 150px;
      }
      .images-up {
        grid-template-columns: repeat(3, 1fr);
      }}  

      
@media (max-width: 480px) {
  .texts {
    width: 90%;
  }
    .manage-products {
      padding: 15px 20px;
     
    }
    .products-table {
      padding: 10px 15px 15px 15px;
    }
    .products-table > p {
      font-size: 18px;
    }
    table {
      font-size: 12px;
    }
    td, th {
      padding: 2px;
    }
    td > img {
      width: 100px;
      height: 100px;
    }
    .images-up {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 320px) {
    .texts {
      width: 100%;
    }
    .manage-products {
      padding: 10px 15px;
      top: 100px;
    }
    .products-table {
      padding: 5px 10px 10px 10px;
    }
    .products-table > p {
      font-size: 16px;
    }
    table {
      font-size: 10px;
    }
    td, th {
      padding: 1px;
    }
    td > img {
      width: 80px;
      height: 80px;
    }
    .images-up {
      grid-template-columns: repeat(2, 1fr);
    }
  }