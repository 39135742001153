.form-container {
    max-width: 550px; /* adjust the width to your liking */
    margin: 0 auto; /* center the form horizontally */
    padding:0px; /* add some padding around the form */
    background-color: #fff; /* add a white background to the form */
    border: 1px solid #ddd; /* add a light gray border around the form */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* add a subtle shadow to the form */
  }

  .details-form>p{
    font-size: 20px;
    padding: 20px 0px;
    font-weight: bold;

  }

  .contact{
   margin-top: 30px;
  }

  .forms{
    padding: 20px;
    width: 100%;
  }

 .inputfields{
    width: 100%;
 }

 .forms>div{
    margin-top: 20px;
 }

 .number{
  display: flex;
  justify-content: space-between;
 }

 .card-title {
  color: #262626;
  font-size: 1.5em;
  line-height: normal;
  font-weight: 700;
  margin-bottom: 0.5em;
}

.small-desc {
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5em;
  color: #452c2c;
}

.small-desc {
  font-size: 1em;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 2em;
  height: 2em;
  overflow: hidden;
  top: 0;
  right: 0;
  background: linear-gradient(135deg, #62d2a2, #62d2a2);
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.contactUpdate{
  margin-left: 85px;
}

.cr {
  display: block;
  position: relative;
  max-width: 300px;
  height: 200px;
  background-color: #f2f8f9;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 2em 1.2em;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  background: linear-gradient(to bottom, white, white);
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: 0 0 10px lightgray;
}

.cr:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: linear-gradient(135deg,#62d2a2, #62d2a2);
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.35s ease-out;
}

.cr:hover:before {
  transform: scale(28);
}

.cr:hover .small-desc {
  transition: all 0.5s ease-out;
  color: rgba(255, 255, 255, 0.8);
}

.card:hover .card-title {
  transition: all 0.5s ease-out;
  color: #ffffff;
}


/* Responsive design */

@media (max-width: 768px) {
  .form-container {
    max-width: 90%;
    padding: 15px;
  }

  .cr {
    max-width: 250px;
    height: 180px;
    padding: 1.5em 1em;
    margin: 10px;
  }
}

@media (max-width: 480px) {
  .form-container {
    max-width: 100%;
    padding: 10px;
  }
  .cr {
    max-width: 100%;
    padding: 1em 0.8em;
    margin: 8px;
  }
  .contactUpdate{
    margin-left: 30px;
  }
}


@media (max-width: 320px) {
  .cr {
    max-width: 290px;
    padding: 1em 0.8em;
    margin: 8px;
  }
  .contactUpdate{
    margin-left: 0px;
  }
}





